import React, { useState, useEffect, useRef } from 'react';

import breakfastClubImage from '../assets/legacy/breakfast.png';
import dlImage from '../assets/dl.png';
import swanImage from '../assets/legacy/Swan.png';
import newDayImage from '../assets/legacy/newday.png';
import bboyImage from '../assets/legacy/bb.png';
import nikkiImage from '../assets/legacy/nikki.png';
import defaultImage from '../assets/legacy/108x.png';
import loaderImage from '../assets/legacy/loading.gif';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { rem } from '@mantine/core';
import { Divider } from '@mantine/core';
import { Button, Drawer, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ReactDOM from 'react-dom';
import ChatRoom from './chatRoom';

import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
const AudioPlayer = ({ url, retryDelay = 5000 }) => {
    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(true);

    const [isLoading, setIsLoading] = useState(true);
    const [errorCount, setErrorCount] = useState(0);
    const [initialLoad, setInitialLoad] = useState(true);

    const autoplay = useRef(Autoplay({ delay: 3000 }));

    const [opened, { open, close }] = useDisclosure(false);

    const [currentDJ, setCurrentDJ] = useState('JZ 94.5');
    const [currentDJImage, setCurrentDJImage] = useState(defaultImage);
    const [newDayMode, setNewDayMode] = useState(false);
    const [currentSubText, setCurrentSubText] = useState('The People\'s Station');
    const [canAutoplayE, setCanAutoplay] = useState(null);

    const [serviceProvider, setServiceProvider] = useState("");
    const [artist, setArtist] = useState("");
    const [artworkUrl, setArtworkUrl] = useState("");
    const [label, setLabel] = useState("");
    const [start, setStart] = useState("");
    const [title, setTitle] = useState("");
    const [trackLength, setTrackLength] = useState("");
    const [trackMixArtist, setTrackMixArtist] = useState("");
    const [trackMixTitle, setTrackMixTitle] = useState("");
    const [trackTitle, setTrackTitle] = useState("");
    const [album, setAlbum] = useState("");

    const [width, setWidth] = useState(window.innerWidth);

    const [viewMore, setViewMore] = useState(false);
    const [adminMode, setAdminMode] = useState(false)

    const [demoMode, setDemoMode] = useState(false)

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        const nowPlayingUrl = 'https://corsproxy.io/?' + encodeURIComponent('https://widgets.autopo.st/fingerprinting/public/jacobybroadnax/nowplaying.json');
        const interval = setInterval(() => {
            fetch(nowPlayingUrl)
                .then(response => response.json())
                .then(data => {
                    // Set each state with the corresponding piece of data
                    setServiceProvider(data.service_provider);
                    setArtist(data.artist);
                    setArtworkUrl(data.artwork_url);
                    setLabel(data.label);
                    setStart(data.start);
                    setTitle(data.title);
                    setTrackLength(data.track_length);
                    setTrackMixArtist(data.track_mix_artist);
                    setTrackMixTitle(data.track_mix_title);
                    setTrackTitle(data.track_title);
                    setAlbum(data.album);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }, 7000);
        return () => clearInterval(interval);


    }, []);

    useEffect(() => {
        const nowPlayingUrl = 'https://corsproxy.io/?' + encodeURIComponent('https://widgets.autopo.st/fingerprinting/public/jacobybroadnax/nowplaying.json');

        fetch(nowPlayingUrl)
            .then(response => response.json())
            .then(data => {
                // Set each state with the corresponding piece of data
                setServiceProvider(data.service_provider);
                setArtist(data.artist);
                setArtworkUrl(data.artwork_url);
                setLabel(data.label);
                setStart(data.start);
                setTitle(data.title);
                setTrackLength(data.track_length);
                setTrackMixArtist(data.track_mix_artist);
                setTrackMixTitle(data.track_mix_title);
                setTrackTitle(data.track_title);
                setAlbum(data.album);
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }, []);

    function canAutoplay() {
        return new Promise((resolve) => {
            let autoplayTestAudio = new Audio();
            autoplayTestAudio.addEventListener('play', function () {
                resolve(true);
            });
            autoplayTestAudio.addEventListener('error', function () {
                resolve(false);
            });
            autoplayTestAudio.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEAgD4AAIA7AAT4//0vAAAAAAAAAAAAAAAAAAAA//MxAA==';
            autoplayTestAudio.play().catch(() => resolve(false));
        });
    }


    useEffect(() => {
        // testing new day mode

        canAutoplay().then(result => setCanAutoplay(result));
        console.log(canAutoplayE);
    }, []);

    const djCheck = () => {
        var now = new Date();
        // Convert local time to Central Time
        var centralTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
        var hours = centralTime.getHours();
        var day = centralTime.getDay();

        if (day >= 1 && day <= 5) { // Weekdays
            console.log('day update');
            if (hours >= 4 && hours < 8) {
                setCurrentDJ('The Breakfast Club');
                setCurrentDJImage(breakfastClubImage);
                setNewDayMode(false);
                setCurrentSubText('The World\'s Most Dangerous Morning Show');
            } else if (hours >= 8 && hours < 13) {
                setCurrentDJ('It\'s a New Day');
                setCurrentDJImage(newDayImage);
                setNewDayMode(true);
                setCurrentSubText('With Rip Daniels & Rowe Evans');
            } else if (hours >= 15 && hours <= 18) {
                setCurrentDJ('D.L. Hughley Show');
                setCurrentDJImage(dlImage);
                setNewDayMode(false);
                setCurrentSubText('With Jasmine Sanders');
            } else if (hours >= 20 && hours <= 22) {
                setCurrentDJ('The Quiet Storm');
                setCurrentDJImage(swanImage);
                setNewDayMode(false);
                setCurrentSubText('With DJ T-Swan');
            }
        } else if (day == 7) { // Sunday
            if (hours == 20 || hours == 21 || hours == 23) {
                setCurrentDJ("Big Boy's Neighborhood");
                setCurrentDJImage(bboyImage);
                setNewDayMode(false);
                setCurrentSubText('Big Laughs, Big Stars, Big Hip Hop');
            }
        } else if (day == 6) { // Saturday
            if (hours == 11) {
                setCurrentDJ('American Blues Network');
                setCurrentDJImage(nikkiImage);
                setNewDayMode(false);
                setCurrentSubText('Top 20 Countdown With Nikki Demarks');
            }
        }
    }

    useEffect(() => {
        djCheck();
        const interval = setInterval(djCheck, 2000);
        return () => clearInterval(interval);
    }, []);





    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    const handleCanPlayThrough = () => {
        setIsLoading(false);
        setInitialLoad(false);
        if (isPlaying) {
            audioRef.current.play();
        }
    };


    const handleError = () => {
        console.error('Error occurred when trying to play audio');
        setErrorCount((count) => count + 1);
    };


    useEffect(() => {
        const audio = audioRef.current;

        // Add event listeners
        audio.addEventListener('canplaythrough', handleCanPlayThrough);
        audio.addEventListener('error', handleError);
        audio.addEventListener('play', () => setIsPlaying(true));
        audio.addEventListener('pause', () => setIsPlaying(false));

        return () => {
            // Remove event listeners
            audio.removeEventListener('canplaythrough', handleCanPlayThrough);
            audio.removeEventListener('error', handleError);
            audio.removeEventListener('play', () => setIsPlaying(true));
            audio.removeEventListener('pause', () => setIsPlaying(false));
        };
    }, []);

    useEffect(() => {
        if (window.location.href.includes('#adminpermission-donotshare')) {
            setViewMore(true);
            setAdminMode(true);
        } else if (window.location.href.includes('#demoMode')) {

            setDemoMode(true);
        }

    }, []);

    useEffect(() => {
        if (errorCount > 0) {
            // Retry after delay if an error occurred
            const timerId = setTimeout(() => {
                console.log('Retrying audio playback');
                setErrorCount(0);
                setIsLoading(true);
                audioRef.current.load();
                if (isPlaying) {
                    audioRef.current.play();
                }
            }, retryDelay);

            return () => clearTimeout(timerId);
        }
    }, [errorCount, isPlaying, retryDelay]);


    useEffect(() => {


        if ('mediaSession' in navigator) {

            navigator.mediaSession.metadata = new MediaMetadata({
                title: currentDJ + " On JZ 94.5 App",
                artist: currentSubText,
                album: 'JZ 94.5',
                artwork: [
                    { src: currentDJImage, sizes: '96x96', type: 'image/png' },

                ],
            });
        }
    }, [currentDJ, currentSubText, currentDJImage]);

    return (
        <div>
            <audio src={url} ref={audioRef} autoPlay />

            <div style={
                width > 1030 && newDayMode ? {
                    marginBottom: '-90px'
                }
                    : null
            } className='music-player'>
                <div className='music-wrapper'>
                    <div className='music-carousel-wrap'>

                        <div className='music-left'>
                            <img className='v' src={currentDJImage} alt='dj' />
                            <div className='music-text'>
                                <h3> {isLoading ?
                                    'Loading Live Stream...' :
                                    "On Air: " + currentDJ
                                }</h3>
                                <p>{currentSubText}</p>
                            </div>
                        </div>
                    </div>

                    <div className='music-right'>
                        {
                            initialLoad ? (
                                <img className='loader-icon' src={loaderImage} />
                            ) : (
                                <button onClick={togglePlayPause}>
                                    {isPlaying ? (
                                        isLoading ? (
                                            <img className='loader-icon' src={loaderImage} />
                                        ) : (
                                            <i className='material-icons-rounded'>
                                                pause
                                            </i>
                                        )
                                    ) : (
                                        <i className='material-icons-rounded'>play_arrow</i>
                                    )}
                                </button>
                            )
                        }


                    </div>
                </div>
                {/*    <p style={{ width: 300 }}>Backup Stream Active: we apoligize for the inconvenience as we work to resolve these issues.</p> */}

                {
                    newDayMode || demoMode ? (

                        <div className='music-activity'>

                            <a href='tel:228-896-9455' target='_blank' rel='noreferrer'>
                                <button className='activity-button'>
                                    <div className='btn-text'>
                                        <i className='material-icons'>call</i>
                                        <p>Call Now (228) 896-9455</p>
                                    </div>
                                    <i className='material-icons'>navigate_next</i>
                                </button>
                            </a>


                            <a >

                                <button onClick={
                                    () => {

                                        setViewMore(true)

                                    }
                                } className='activity-button'>
                                    <div className='btn-text'>
                                        <i className='material-icons'>chat</i>
                                        <p>Join Chat Room</p>
                                    </div>
                                    <i className='material-icons'>navigate_next</i>


                                </button>
                            </a>
                        </div>
                    ) : null
                }

            </div>

            <Drawer opened={opened} onClose={close}>
                <iframe className='chat-iframe' src="https://my.cbox.ws/JZ945" width="100%" height="100vh" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </Drawer>

            {ReactDOM.createPortal(
                <div >

                    <>
                        <SwipeableBottomSheet bodyStyle={{ padding: '18px', borderRadius: adminMode ? '0px' : '20px 20px 0px 0px', backgroundColor: adminMode ? 'white' : 'transparent', maxWidth: adminMode ? '100%' : '900px', margin: 'auto', height: adminMode ? '100vh' : '97vh' }} style={{ zIndex: 350 }} overlayStyle={{
                            backdropFilter: 'blur(20px)',
                        }} overflowHeight={0} open={viewMore} fullscreen={true} onChange={
                            () => {
                                setViewMore(false)
                            }
                        }>
                            <div className='modal-screen chat-modal'
                            >

                                {
                                    adminMode || demoMode ? <div className='nav-close-container'>
                                        <button className='nav-close' onClick={

                                            () => setViewMore(false)

                                        }>
                                            <i className='material-icons'>close</i>
                                        </button>
                                    </div> : <div className='chat-header'>
                                        <div className='modal-pill'>
                                            <div className='modal-pill-inner'></div>
                                        </div>
                                        <h1 className='modal-title chat-title' >
                                            New Day Chat Room
                                        </h1>

                                        <div className='nav-close-container'>
                                            <button className='nav-close' onClick={

                                                () => setViewMore(false)

                                            }>
                                                <i className='material-icons'>close</i>
                                            </button>
                                        </div>
                                    </div>
                                }



                                {
                                    viewMore ? <ChatRoom userName={"Guest User"} /> : null
                                }
                            </div>
                        </SwipeableBottomSheet>
                    </>

                </div>,
                document.getElementById('modal-root')
            )}
        </div>
    );
};

export default AudioPlayer;
